<template>
  <v-card class="mx-auto" color="white" outlined>
    <v-row align="center">
      <v-col cols="2">
        <v-radio-group v-model="find" label="Buscar por:" @change="find_change()">
          <v-radio label="RUT" value="rut" />
          <v-radio label="Area" value="area" />
        </v-radio-group>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-if="find == 'rut'"
          v-mask="['X.XXX.XXX-X', 'XX.XXX.XXX-X']"
          v-model="rut"
          required
          prepend-inner-icon="mdi-card-account-details-outline"
          label="Ingrese rut"
          append-icon="mdi-magnify"
          maxlength="12"
          placeholder="12.345.678-9"
          @click:append="getData()"
          @keydown.enter="getData()"
        >
        </v-text-field>
        <v-select
          v-else
          v-model="area"
          dense
          :items="areas"
          append-icon="mdi-magnify"
          @click:append="getData()"
          @keydown.enter="getData()"
        />
      </v-col>
    </v-row>
    <v-row v-if="totalItems > 25" align="center" class="list mx-auto">
      <v-col cols="3">
        <v-select
          v-model="pageSize"
          :items="pageSizes"
          label="Articulos/Página"
          centered
          @change="handlePageSizeChange"
        />
      </v-col>
      <v-col cols="6">
        <v-pagination
          v-if="totalPages > 1"
          v-model="page"
          :length="totalPages"
          total-visible="10"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          @input="handlePageChange"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1">
        <v-btn
          small
          @click="
            pageSize = 20000;
            exportE();
          "
        >
          Exportar
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="list mx-auto" tile>
      <v-row>
        <v-card-title>Entregas de elementos de Seguridad</v-card-title>
        <v-spacer />
        <v-card-subtitle v-if="currentItems" v-text="'Mostrando ' + currentItems + ' de ' + totalItems" />
      </v-row>
      <v-data-table :items="userdata" :headers="headers" dense disable-pagination :hide-default-footer="true">
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-icon class="mr-2">
                mdi-information-variant
              </v-icon>
            </td>
            <td>{{ item.FECHA }}</td>
            <td>{{ item.NOMBRE }}</td>
            <td>{{ item.CECO }}</td>
            <td>{{ item.NCECO }}</td>
            <td>{{ item.T_MOV }}</td>
            <td>
              <v-checkbox v-model="item.BOTA" :value="item.BOTA" readonly />
            </td>
            <td>{{ item.TALLA }}</td>
            <td>
              <v-checkbox v-model="item.FONO" :value="item.FONO" readonly />
            </td>
            <td>
              <v-checkbox
                v-model="item.ENTREGA"
                :value="item.ENTREGA"
                :readonly="item.ENTREGA"
                @change="confirmEntrega(item.id)"
              />
            </td>
          </tr> </template
      ></v-data-table>
    </v-card>

    <v-dialog v-model="success_resp" width="50%">
      <v-alert border="right" colored-border type="success" elevation="2">
        <v-row align="center">
          <v-col> Correcto!<br /><br />El movimiento ha sido registrado de formna exitosa </v-col>
        </v-row>
        <br />
      </v-alert>
    </v-dialog>

    <v-dialog persistent v-model="confirmdial" width="50%">
      <v-card height="180">
        <v-card-title class="text-h5 cyan">
          Información
        </v-card-title>
        <v-card-text class="text-subtitle-1">
          <v-icon x-large color="cyan">mdi-warning-outline</v-icon>
          Se confirmara la entrega de los elementos indicados al colaborador
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row align="center" justify="center">
            <v-btn color="primary" text @click="cancelEntrega()">
              Cancelar
            </v-btn>
            <v-spacer />
            <v-btn color="primary" text @click="make_entrega()">
              Confirmar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :opacity="0.8" :value="overlay">
      <!-- <v-progress-circular indeterminate size="150">
        descargando...
      </v-progress-circular> -->
      Descargando archivo solicitado...
      <v-progress-linear height="10" indeterminate striped color="deep-orange" />
    </v-overlay>
  </v-card>
</template>

<script>
import botasService from "../services/botasService";
import XLSX from "xlsx";
export default {
  data: () => ({
    rut: undefined,
    area: undefined,
    userdata: [],
    mov_data: [],
    find: "rut",
    headers: [
      { text: "", value: "actions", align: "center", sortable: false },
      { text: "Fecha", value: "FECHA", align: "start", sortable: false },
      { text: "Nombre", value: "NOMBRE", align: "start", sortable: false },
      { text: "CeCo", value: "CECO", align: "start", sortable: false },
      { text: "Nombre CeCo", value: "NCECO", align: "start", sortable: false },
      { text: "Tipo Entrega", value: "T_MOV", align: "start", sortable: false },
      { text: "Bota", value: "BOTA", align: "start", sortable: false },
      { text: "Talla", value: "TALLA", align: "start", sortable: false },
      { text: "Fono", value: "FONO", align: "start", sortable: false },
      { text: "Entregado", value: "ENTREGA", align: "start", sortable: false },
    ],
    areas: [
      { text: "Administración", value: "ADMINISTRACION" },
      { text: "Ahumado", value: "AHUMADO" },
      { text: "Bodega", value: "BODEGA" },
      { text: "Casino", value: "CASINO" },
      { text: "Contratista", value: "CONTRATISTA" },
      { text: "Faena", value: "FAENA" },
      { text: "Frigorifico", value: "FRIGORIFICO" },
      { text: "Infraestructura", value: "INFRAESTRUCTURA" },
      { text: "Lavanderia", value: "LAVANDERIA" },
      { text: "Mantenimiento", value: "MANTENIMIENTO" },
      { text: "Otros", value: "OTROS" },
      { text: "Porteria", value: "PORTERIA" },
      { text: "Procesos Posteriores", value: "POSTERIORES" },
      { text: "Pulpas", value: "PULPAS" },
      { text: "SEMA", value: "SEMA" },
      { text: "Servicios Generales", value: "SS.GG." },
      { text: "Trozados", value: "TROZADOS" },
    ],
    overlay: false,
    nodata: false,
    confirmdial: false,
    success_resp: false,
    page: 1,
    totalPages: 0,
    currentItems: undefined,
    totalItems: 0,
    pageSize: 25,
    pageSizes: [25, 50, 100],
  }),
  computed: {
    user: function() {
      let user = JSON.parse(sessionStorage.getItem("user"));
      return user.nombre;
    },
  },
  methods: {
    getData() {
      let data = {};
      if (this.rut) {
        data["rut"] = this.rut.replace(/\./g, "").toUpperCase();
      } else {
        data["area"] = this.area;
      }
      if (this.page) {
        data["page"] = this.page - 1;
      }
      if (this.pageSize) {
        data["size"] = this.pageSize;
      }
      botasService.getEntregas(data).then((response) => {
        console.log(response);
        if (response.data.entregas.length < 1) {
          this.nodata = true;
        } else {
          this.userdata = response.data.entregas.map(this.mapEntregas);
          this.totalPages = response.data.totalPages;
          this.currentItems = response.data.currentItems;
          this.totalItems = response.data.totalItems;
          this.nodata = false;
        }
        console.log(this.nodata);
      });
    },
    exportE() {
      let data = {};
      if (this.rut) {
        data["rut"] = this.rut.replace(/\./g, "").toUpperCase();
      } else {
        data["area"] = this.area;
      }
      if (this.page) {
        data["page"] = this.page - 1;
      }
      if (this.pageSize) {
        data["size"] = this.pageSize;
      }
      this.overlay = true;
      botasService.getEntregas(data).then((response) => {
        let arrentregas = [];
        for (let i = 0; i < response.data.entregas.length; i++) {
          let tmov_;
          if (response.data.entregas[i]["t_mov"] == "new") {
            tmov_ = "Nueva";
          } else if (response.data.entregas[i]["t_mov"] == "replace") {
            tmov_ = "Reposición";
          } else {
            tmov_ = "Renovación";
          }
          let fecha1 = response.data.entregas[i]["fecha_reg"].toString().split("T")[0];
          let fecha2 = response.data.entregas[i]["fecha_entr"]
            ? response.data.entregas[i]["fecha_entr"].toString().split("T")[0]
            : "";
          const arritem = {
            FECHA: fecha1,
            RUT: response.data.entregas[i]["rut"],
            NOMBRE: response.data.entregas[i]["nom_comp"],
            CECO: response.data.entregas[i]["ceco"],
            DESC_CECO: response.data.entregas[i]["nom_ceco"],
            AREA: response.data.entregas[i]["area"],
            DESC_ENTREGA: tmov_,
            ENTREGA_BOTAS: response.data.entregas[i]["bota"] ? "SI" : "NO",
            TALLA_BOTA: response.data.entregas[i]["talla_b"],
            ENTREGA_FONO: response.data.entregas[i]["fono"] ? "si" : "no",
            USUARIO_REGISTRADOR: response.data.entregas[i]["usr_reg"],
            "ESTADO ENTREGA": response.data.entregas[i]["entregado"] ? "ENTREGADO" : "PENDIENTE",
            FECHA_ENTREGA: fecha2,
            USUARIO_ENTREGA: response.data.entregas[i]["usr_entr"] ? response.data.entregas[i]["usr_entr"] : "",
          };
          arrentregas.push(arritem);
        }
        let codig = XLSX.utils.json_to_sheet(arrentregas);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, codig, "ENTREGAS");
        XLSX.writeFile(wb, "ENTREGAS.xlsx");
        this.overlay = false;
      });
    },
    async confirmEntrega(id) {
      console.log(id);
      for (let i = 0; i < this.userdata.length; i++) {
        if (this.userdata[i].id == id) {
          console.log(i);
          this.mov_data["id"] = id;
          this.mov_data["index"] = i;
          this.confirmdial = true;
        }
      }
      this.confirmdial = true;
    },
    cancelEntrega() {
      this.userdata[this.mov_data.index].ENTREGA = false;
      this.mov_data = [];
      this.confirmdial = false;
    },
    make_entrega() {
      let data = {};
      data["id"] = this.mov_data.id;
      data["user"] = this.user;
      botasService.entregar(data).then((response) => {
        console.log(response);
        this.mov_data = [];
        this.confirmdial = false;
        this.success_resp = true;
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getData();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getData();
    },
    mapEntregas(data) {
      let tmov_;
      if (data.t_mov == "new") {
        tmov_ = "Nueva";
      } else if (data.t_mov == "replace") {
        tmov_ = "Reposición";
      } else {
        tmov_ = "Renovación";
      }
      let fecha = data.fecha_reg.toString().split("T")[0];
      return {
        id: data.id,
        FECHA: fecha,
        NOMBRE: data.nom_corto,
        CECO: data.ceco,
        NCECO: data.nom_ceco,
        T_MOV: tmov_,
        BOTA: data.bota,
        TALLA: data.talla_b,
        FONO: data.fono,
        ENTREGA: data.entregado,
      };
    },
    close_nodata() {
      this.nodata = false;
    },
    find_change() {
      this.rut = undefined;
      this.area = undefined;
      this.userdata = [];
    },
  },
  mounted() {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (!user) {
      this.$router.push("/login");
    }
  },
};
</script>
